<template>

  <div style="float:right" class="btn btn-god" :class="{'active': flag_open}"
       @click="open_all">Открыть все</div>
  <h1>Монитор операций</h1>
  <div class="wrp-operations">
    <div class="operation" v-for="(operation, ind) in operations" :key="operation.id"
         :class="{ 'open': flag_open == true, 'close': flag_open == false}"
         style="position:relative">
      <router-link :to="operation.name" class="inner-operation" >
        <div class="inner-operation--title">{{ operation.label }} ({{operation.cnt}})</div>
        <div v-for="(order, index) in operation.orders" :key="index" class="order-info" :class="{
            'white': order.statusBuff > 100,
            'green' : order.statusBuff >= 66 && order.statusBuff <= 100,
            'yellow': order.statusBuff >= 33 && order.statusBuff < 66,
            'red': order.statusBuff >= 0 && order.statusBuff < 33,
            'black': order.statusBuff < 0,
            }">
          <!--<a @click.stop="get_scheme_technology(ind, index)" href="javascript:void(0)">
            <i class="fa fa-tasks" aria-hidden="true"></i>
          </a>-->
          {{order.priority}}. {{order.number}}
          <template v-if="order.elements">
            <div style="float:right">
              &nbsp;
              <div @click.stop.prevent="toggleOperations(ind, index)" class="table-body-td pointer"
                    style="flex:2;"
                   v-if="order.show === false">
                ({{order.elements.length}})
                <i class="fa fa-plus" aria-hidden="true"></i>
              </div>
              <div @click.stop.prevent="toggleOperations(ind, index)" class="table-body-td pointer"
                    style="flex:2" v-else >
                ({{order.elements.length}})
                <i class="fa fa-minus" aria-hidden="true"></i>
              </div>
            </div>
            <div v-if="order.show">
              <div v-for="(elem, i) in order.elements" :key="i">
                <span>{{elem.oper ? elem.oper : 'Не определено'}}</span>
              </div>
            </div>
          </template>
        </div>
      </router-link>
      <!--
      <a href="javascript:void(0)" class="btn-collapse">
        <i class="fa fa-angle-double-down" aria-hidden="true"></i>
      </a>
      -->
    </div>
  </div>

  <transition name="modal">
    <Modal v-if="showModal" @close="showModal = false">
      <template v-slot:header>
        <h3>Заказ: {{operations_tech_number}}</h3>
      </template>
      <template v-slot:body>
        <div class="custom-table-green">
          <div v-for="(ops,index) in operations_tech" :key="index">
            <div class="wrp-elem">
              <div>{{index ? index : 'Элемент не определен'}}</div>
              <div class="wrp-elem-item">
                <template v-for="(op, index2) in ops" :key="index2">
                  <div v-if="op.color == 'green'" class="elem-item-green">
                    <strong class="stick">{{op.sort}}</strong> {{op.label}}
                  </div>
                  <div v-else-if="op.color == 'red'" class="elem-item-red">
                    <strong class="stick">{{op.sort}}</strong> {{op.label}}
                  </div>
                  <div v-else-if="op.color == 'blue'" class="elem-item-blue">
                    <strong class="stick">{{op.sort}}</strong> {{op.label}}
                  </div>
                  <div v-else-if="op.color == 'gray'" class="elem-item-gray">
                    <strong class="stick">{{op.sort}}</strong> {{op.label}}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </transition>

</template>

<script>
import axios from 'axios';
import Modal from '../../components/help/Modal.vue';

export default {
  components: {
    Modal,
  },
  name: 'Operations',
  data() {
    return {
      operations: [],
      flag_open: false,
      error: [],
      operations_tech: [],
      operations_tech_number: '',
      showModal: false,
    };
  },
  created() {
    this.getAllOperations();
  },
  methods: {
    getAllOperations() {
      const str = '/api/list-operations-test2';
      axios.get(str)
        .then((res) => {
          if (res.data.success === 1) {
            this.operations = res.data.list_operation;
          }
        })
        .catch((e) => {
          this.error.push(e);
        });
    },
    open_all() {
      if (this.flag_open) {
        this.flag_open = false;
      } else {
        this.flag_open = true;
      }
    },
    get_scheme_technology(ind, index) {
      this.showModal = true;
      this.operations_tech_number = this.operations[ind].orders[index].number;
      const str = '/api/order-scheme-technology?val=';
      axios.get(str + this.operations[ind].orders[index].id_order_head)
        .then((res) => {
          if (res.data.success === 1) {
            this.operations_tech = res.data.operations;
          }
        })
        .catch((e) => {
          this.error.push(e);
        });
    },
    toggleOperations(ind, index) {
      if (this.operations[ind].orders[index].show) {
        this.operations[ind].orders[index].show = false;
      } else {
        this.operations[ind].orders[index].show = true;
      }
    },
  },
};
</script>

<style scoped>
.order-info{ margin-right:-10px; margin-left:-10px; padding:0px 10px;}

.wrp-operations{
  margin-left: -12px;
  margin-right: -12px;
  display:flex; flex-wrap: wrap;
}
.operation{
  padding:12px;
  overflow:hidden;
  position:relative;
}

.inner-operation{
  display:block;
  text-decoration:none;
  /* color: #2c3e50; */
  /* background: #c0dfcb; */
  color:#eee;
  background: #3e5977;
  border: 1px solid #a8c8b3;
  padding:5px 10px 0px;
  border-radius:4px
}
.inner-operation:hover{
  /*
  background: #afd2bb;
  border: 1px solid #9dbba7;
  */
  background: #537499;
}

.inner-operation--title{font-weight:bold; padding-bottom:6px;}

.black{background: #14171a; color: #eee;}
.black a{color: #eee}
.red{background: #e33838; color: #eee;}
.red a{color:#eee}
.yellow{background: #f2f251; color: #000;}
.yellow a{color: #000}
.green{background: #2e842e; color: #eee;}
.green a {color: #eee}
.white{background: #ffffff; color: #111;}
.white a{color:#111;}
.open{height:auto}
.close{max-height:410px;}

.btn-collapse{display:block; text-align:center; color:#fff;
  position:absolute; bottom:0px; left:0px; width:100%; background:#537499;}
.btn-god{padding: 4px 10px; box-shadow: 1px 1px 1px #000; background: #c1c1c1;
  transition:1s; cursor:pointer; margin-top:6px;}
.btn-god.active{ box-shadow: 1px 1px 1px #000 inset; background: #bbe3c1;}

.wrp-elem{background: #e4e5ec; border: 1px solid #97a4dd;
  argin-bottom:5px; padding-left:5px; overflow-x: auto; overflow-y: hidden;}
.wrp-elem-item{display:flex; align-items: start; justify-content: start;}

.elem-item-green{background: #8dd5a9; border: 1px solid #5f8e71; position:relative;
  padding: 5px 10px 5px 20px; margin-right: 5px; height:48px; margin-bottom:5px;}
.elem-item-gray{background: #ccc; border: 1px solid #aaa; position:relative;
  padding: 5px 10px 5px 20px; margin-right: 5px; height:48px; margin-bottom:5px;}
.elem-item-red{background: #d58d8d; border: 1px solid #8e5f5f; position:relative;
  padding: 5px 10px 5px 20px; margin-right: 5px; height:48px; margin-bottom:5px;}
.elem-item-blue{background: #8dc4d5; border: 1px solid #5f6f8e; position:relative;
  padding: 5px 10px 5px 20px; margin-right: 5px; height:48px; margin-bottom:5px;}

.wrp-elem-item .stick{position:absolute; left:0px; top:0px; }

.table-body-td.pointer{display:inline-block;}
</style>
